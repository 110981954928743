import * as React from "react"

import { useIntl } from "gatsby-plugin-intl-v4"

import Header from "../header"
import Footer from "../footer"
import CenteredText from "../centeredText"
import GalleryAndPreview from "../galleryAndPreview"

import ReactFullpage from "@fullpage/react-fullpage";

const Gallery = () => {
    const intl = useIntl();
    return (

        <div className={`pageContainer`}>
            <Header hidePlaceholder={true} />
            <ReactFullpage
                licenseKey={'WNK68-CN51I-6KNC7-J1276-CSYRO'}
                sectionSelector={"section"}
                afterLoad={function (origin, destination, direction) {

                    setTimeout(() => {
                        if (destination.item.classList.contains("footerSection")) {
                            destination.item.classList.remove("fp-auto-height");
                        }
                    }, 250)

                }}
                onLeave={function (origin, destination, direction) {

                    if (origin.item.classList.contains("footerSection")) {
                        origin.item.classList.add("fp-auto-height");
                    }

                }}
                render={(comp) => (
                    <ReactFullpage.Wrapper>
                        <section className="active">
                            <GalleryAndPreview />
                        </section>
                        <section className="footerSection fp-auto-height" style={{ backgroundColor: "#d9d9d9" }}>
                            <CenteredText
                                main={intl.formatMessage({ id: 'gallery.chooseFloor' })}
                                linkText={intl.formatMessage({ id: 'gallery.seePlan' })}
                                linkTo={"/plans/"}
                            />
                            <Footer variant={2} hideBorder={true} />
                        </section>
                    </ReactFullpage.Wrapper>
                )}
            />
        </div>
    )
}

export default Gallery
