import { useStaticQuery, graphql } from "gatsby"

const useGalleryPreview = () => {
    const data = useStaticQuery(graphql`
      query Preview {
        allFile(filter: {relativeDirectory: {eq: "gallery"}}, sort: {fields: name}) {
          nodes {
            id
            name
            childImageSharp {
                gatsbyImageData(height: 1000, layout: CONSTRAINED)
            }
          }
        }
      }
    `);

    return data.allFile.nodes.map(node => ({
        ...node.childImageSharp,
        id: node.id,
        name: node.name
    }));
};

export default useGalleryPreview;