// extracted by mini-css-extract-plugin
export var backText = "galleryAndPreview-module--backText--6m+S2";
export var explanation = "galleryAndPreview-module--explanation--0iBl1";
export var galleryAndPreview = "galleryAndPreview-module--galleryAndPreview--4QkcA";
export var imageThumbnail = "galleryAndPreview-module--imageThumbnail--gAZ2W";
export var landscape = "galleryAndPreview-module--landscape--IuEAK";
export var limitWidth = "galleryAndPreview-module--limitWidth--zw-Da";
export var portrait = "galleryAndPreview-module--portrait--QmssR";
export var preview = "galleryAndPreview-module--preview--xVnU9";
export var previewContainer = "galleryAndPreview-module--previewContainer--v6qDo";
export var previewImage = "galleryAndPreview-module--previewImage--biDy3";
export var thumbnail = "galleryAndPreview-module--thumbnail--W1Scy";
export var thumbnailList = "galleryAndPreview-module--thumbnailList--ib9-W";
export var thumbnailListW = "galleryAndPreview-module--thumbnailListW--yUhDx";
export var thumbnails = "galleryAndPreview-module--thumbnails--SJevt";