import * as React from "react"
import { Link } from "gatsby-plugin-intl-v4"

import * as styles from "./centeredText.module.css"

const CenteredText = ({
  main,
  linkText,
  linkTo
}) => {

  return (
    <div className={styles.centeredText}>
      <h2 className={styles.main}>
        {main}
      </h2>
      <Link to={linkTo} className={styles.link}>{linkText}</Link>
    </div>
  )
}

export default CenteredText