import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./galleryAndPreview.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useGallery from "../useGallery"
import useGalleryPreview from "../useGalleryPreview"

const GalleryAndPreview = () => {

  const intl = useIntl();

  const galleryImg = useGallery();
  const galleryPreview = useGalleryPreview();
  const [indexImage, setIndexImage] = React.useState(0)

  return (
    <div className={`row ${styles.galleryAndPreview}`}>
      <div className={`col-xl-6 ${styles.thumbnails}`}>
        <div className={styles.thumbnailListW}>
          <div className={styles.thumbnailList}>
            {
              galleryImg.map((i, k) =>
                <div key={k} className={styles.thumbnail} role='presentation' onMouseEnter={(e) => {
                  setIndexImage(k)
                }}>
                  <GatsbyImage
                    key={k}
                    image={getImage(i)}
                    alt={i[intl.locale]}
                    className={`${styles.imageThumbnail} ${i.isPortrait ? styles.portrait : styles.landscape}`}
                  />
                </div>
              )
            }
          </div>
        </div>
        <div className={styles.explanation}>
          <p>{intl.formatMessage({ id: 'gallery.explanation' })}</p>
        </div>
      </div>

      <div className={`col-xl-6 d-none d-xl-flex ${styles.previewContainer}`}>
        <div className={styles.preview}>
          <div className={styles.limitWidth}>
            <GatsbyImage
              image={getImage(galleryPreview[indexImage])}
              alt={galleryImg[indexImage][intl.locale]}
              quality={90}
              className={styles.previewImage}
            />
          </div>
        </div>
        <div className={styles.explanation}>
          <p>{galleryImg[indexImage][intl.locale]}</p>
        </div>
      </div>
    </div>
  )
}

export default GalleryAndPreview