import { useStaticQuery, graphql } from "gatsby"

let description = [
  {
    name: "Mansfield_018_L",
    isPortrait: true,
    en: "Yoga room",
    fr: "Salle de yoga"
  },
  {
    name: "Mansfield_019_L",
    isPortrait: false,
    en: "Unit 1803 - Bedroom",
    fr: "Unité 1803 - Chambre à coucher"
  },
  {
    name: "Mansfield_020_L",
    isPortrait: true,
    en: "Outdoor lounge area on the rooftop",
    fr: "Espace lounge extérieur amenagé sur le toit"
  },
  {
    name: "Mansfield_021_L",
    isPortrait: true,
    en: "Spa and sauna",
    fr: "Spa et sauna"
  },
  {
    name: "Mansfield_022_L",
    isPortrait: true,
    en: "Lounge area",
    fr: "Espace lounge"
  },
  {
    name: "Mansfield_023_L",
    isPortrait: true,
    en: "Party room",
    fr: "Salle de célébration et bibliothèque"
  },
  {
    name: "Mansfield_024_L",
    isPortrait: false,
    en: "Mansfield’s rooftop infinity pool",
    fr: "Piscine à débordement sur le toit"
  },
  {
    name: "Mansfield_025_L",
    isPortrait: true,
    en: "Fitness centre",
    fr: "Salle d'entrainement"
  },
  {
    name: "Mansfield_026_L",
    isPortrait: true,
    en: "Indoor golf simulator",
    fr: "Simulateur de golf intérieur"
  },
  {
    name: "Mansfield_027_L",
    isPortrait: true,
    en: "Work and conference rooms",
    fr: "Salle de travail et de conférence"
  },
  {
    name: "Mansfield_028_L",
    isPortrait: false,
    en: "Unit 1509 - Kitchen and Dining Room",
    fr: "Unité 1509 - Cuisine et salle à manger"
  },
  {
    name: "Mansfield_029_L",
    isPortrait: false,
    en: "Urban landscaped connection",
    fr: "Ruelle d'ambiance"
  },
  {
    name: "Mansfield_030_L",
    isPortrait: false,
    en: "Unit 1509 - Bedroom",
    fr: "Unité 1509 - Chambre à coucher"
  },
  {
    name: "Mansfield_031_L",
    isPortrait: true,
    en: "Penthouse - Bathroom",
    fr: "Penthouse - Salle du bain"
  },
  {
    name: "Mansfield_028_L32",
    isPortrait: true,
    en: "Penthouse - Bathroom",
    fr: "Penthouse - Balcon"
  }
]

const useGallery = () => {
  const data = useStaticQuery(graphql`
      query Thumbnails {
        allFile(filter: {relativeDirectory: {eq: "gallery"}}, sort: {fields: name}) {
          nodes {
            id
            name
            childImageSharp {
                gatsbyImageData(height: 500, layout: CONSTRAINED)
            }
          }
        }
      }
    `);

  return data.allFile.nodes.map(node => ({
    ...node.childImageSharp,
    id: node.id,
    name: node.name,
    isPortrait: description.filter(d => d.name === node.name)[0].isPortrait,
    en: description.filter(d => d.name === node.name)[0].en,
    fr: description.filter(d => d.name === node.name)[0].fr
  }));
};

export default useGallery;