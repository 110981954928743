import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Gallery from "../components/pages/Gallery"

import { useIntl } from "gatsby-plugin-intl-v4"

const GalleryPage = () => {

  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'h1.gallery' })}
        description={intl.formatMessage({ id: 'seo.description' })}
        pageName={'gallery'}
      />
      <Gallery />
    </Layout>
  )
}

export default GalleryPage
